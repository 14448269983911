import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PageSection from "../../components/pageSection"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const CoverToCoverPrivacyPolicy = () => {
  const { contentfulCoverToCoverPrivacyPolicy } = useStaticQuery(graphql`
    query {
      contentfulCoverToCoverPrivacyPolicy {
        content {
          content
          json
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Cover to Cover Privacy Policy" />
      <PageSection first>
        <div>
          {documentToReactComponents(
            contentfulCoverToCoverPrivacyPolicy.content.json
          )}
        </div>
      </PageSection>
    </Layout>
  )
}

export default CoverToCoverPrivacyPolicy
